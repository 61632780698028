<template>
  <div id="header-component">
    <div class="header-desktop">
      <div class="d-flex justify-content-between p-3">
        <div class="d-flex">
          <div class="header-main-logo">
            <router-link class="nav-link text-white p-0 ml-5" :to="{ name: 'dashboard-index' }">
              <img :src="require('../../../public/images/logo-adesa.png')" alt="Logo Adesa">
            </router-link>
          </div>

          {{ /* ICONS MENU */ }}
          <div>
            <b-icon
                class="ml-2 mt-2"
                icon="list"
                font-scale="2.5"
                variant="primary"
                v-on:click="clickEventBurger()">
            </b-icon>
          </div>
        </div>

        <div class="alert-connect-as d-none d-lg-none d-xl-block" v-if="getIsAdminConnectAs">
          <b-alert show variant="warning" class="d-flex justify-content-between">
            <span class="mt-2 mr-2 d-none d-lg-none d-xl-block">
              {{ $t('header.connectAs.message') }} <b>{{ $store.getters.getCurrentUser.username }}</b>
            </span>
            <div class="text-center">
              <button class="btn btn-outline-dark" v-on:click="backToAdmin">{{ $t('header.connectAs.button') }}</button>
            </div>
          </b-alert>
        </div>

        {{ /* HEADER */ }}
        <div class="col-sidebar">
          <div class="d-flex">
            <SwitchLanguage @emit-locale-selected="onChangeLanguage"/>
            <div>
              <a class="btn btn-outline-warning mt-1 mr-2 d-none d-lg-none d-xl-block" v-on:click="redirectClaimForm">
                {{ $t('header.button.claimForm') }}
              </a>
            </div>

            <div>
              <a class="btn btn-outline-info mt-1 mr-2 d-none d-lg-none d-xl-block" v-on:click="redirectFrontReseller">
                {{ $t('header.button.calculator') }}
              </a>
            </div>

            <b-dropdown id="dropdown-right" left text="Right align" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <div class="icon-user-header ml-2">
                  <b-icon icon="person-circle" font-scale="2" variant="primary"></b-icon>
                </div>
              </template>
              <b-dropdown-text class="text-center">
                <div class="w-100">
                  <h5 class="text-info mt-1">{{ $t('header.dropdownUser.myAccount') }}</h5>
                </div>
                <hr />
                <div class="text-secondary">
                  {{ (this.$store.getters.getCurrentUser) ? this.$store.getters.getCurrentUser.firstname : '' }}
                  {{ (this.$store.getters.getCurrentUser) ? this.$store.getters.getCurrentUser.lastname : '' }}
                  <br />
                  ({{ (this.$store.getters.getCurrentUser) ? this.$store.getters.getCurrentUser.username : '' }})
                </div>
              </b-dropdown-text>
              <b-dropdown-divider v-if="getIsAdminConnectAs"></b-dropdown-divider>
              <b-dropdown-item  v-on:click="backToAdmin" v-if="getIsAdminConnectAs">
                <div class="d-flex justify-content-center p-1">
                  <span class="ml-4">{{ $t('header.connectAs.button') }}</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item  v-on:click="redirectFrontReseller">
                <div class="d-flex justify-content-center p-1">
                  <b-icon class="" icon="box-arrow-up-right" font-scale="1" variant="secondary"></b-icon>
                  <span class="ml-4">{{ $t('header.button.calculator') }}</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item href="/reseller/profile" v-if="this.$store.getters.userIsReseller">
                {{ $t('header.dropdownUser.profilePage') }}
              </b-dropdown-item>
              <b-dropdown-item href="/logout">{{ $t('header.dropdownUser.logout') }}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    showSidebar: {
      type: Boolean
    },
  },
  data() {
    return {
      hrefFrontResellerUrl: process.env.VUE_APP_FRONT_RESELLER_URL,
      hrefClaimFormUrl: process.env.VUE_APP_CLAIM_FORM_URL,
      getIsAdminConnectAs: !!(localStorage.getItem('auth.adminId'))
    }
  },
  methods: {
    clickEventBurger() {
      let boolShowSidebar = !this.showSidebar
      this.$emit('click-event-burger', { showSidebar : boolShowSidebar })
    },
    backToAdmin() {
      this.$swal({
        title: this.$t('header.connectAs.modal.title'),
        text: this.$t('header.connectAs.modal.message'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('header.connectAs.adminMode')
      }).then((result) => {
        if(result.value) {
          this.$store.dispatch('backToAdmin', localStorage.getItem('auth.adminId'))
        }
      })
    },
    redirectFrontReseller()
    {
      window.open(this.hrefFrontResellerUrl)
    },
    redirectClaimForm()
    {
      window.open(this.hrefClaimFormUrl)
    },
    onChangeLanguage(model)
    {
      this.$emit('emit-from-header-locale-selected', model)
    }
  },
  created() {
  },
  computed: {
    ...mapState(['AuthModule']),
    ...mapState(['UserModule']),
  },
  components: {
    SwitchLanguage: () => import('@/components/_shared/switchLocale')
  },
}
</script>